var render = function render(){
  var _vm$form, _vm$form$meta, _vm$form2, _vm$form2$meta, _vm$form3, _vm$form3$meta, _vm$form4, _vm$form4$meta, _vm$form5, _vm$form5$meta, _vm$form6, _vm$form6$meta, _vm$form7, _vm$form7$meta, _vm$form8, _vm$form8$meta, _vm$form9, _vm$form9$meta, _vm$form10, _vm$form10$meta, _vm$form11, _vm$form11$meta, _ref, _vm$form12, _vm$form13, _vm$form14, _vm$form14$meta, _vm$form15, _vm$form15$meta, _vm$form16;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('div', {
    staticClass: "form-container"
  }, [_c('div', [_vm._m(0), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "이름"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$meta = _vm$form.meta) === null || _vm$form$meta === void 0 ? void 0 : _vm$form$meta.name) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : (_vm$form2$meta = _vm$form2.meta) === null || _vm$form2$meta === void 0 ? void 0 : _vm$form2$meta.phone) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "이메일"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : (_vm$form3$meta = _vm$form3.meta) === null || _vm$form3$meta === void 0 ? void 0 : _vm$form3$meta.email) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "주소"
    }
  }, [!!_vm.form.meta.postcode ? [_vm._v(" [" + _vm._s(_vm.form.meta.postcode) + "] ")] : _vm._e(), !!_vm.form.meta.address1 ? [_vm._v(" " + _vm._s(_vm.form.meta.address1) + " ")] : _vm._e(), !!_vm.form.meta.address2 ? [_vm._v(" " + _vm._s(_vm.form.meta.address2) + " ")] : _vm._e()], 2)], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(1), _c('v-divider', {
    staticClass: "primary border-2"
  }), _c('div', {
    staticClass: "py-20 px-md-10 py-md-36"
  }, [_c('div', {
    staticClass: "mt-n8 mt-md-n14"
  }, [_c('myinfo-row', {
    attrs: {
      "label": "작가명"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : (_vm$form4$meta = _vm$form4.meta) === null || _vm$form4$meta === void 0 ? void 0 : _vm$form4$meta.author) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "작품명"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : (_vm$form5$meta = _vm$form5.meta) === null || _vm$form5$meta === void 0 ? void 0 : _vm$form5$meta.subject) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "작품의 재료"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : (_vm$form6$meta = _vm$form6.meta) === null || _vm$form6$meta === void 0 ? void 0 : _vm$form6$meta.material) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "작품의 크기"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : (_vm$form7$meta = _vm$form7.meta) === null || _vm$form7$meta === void 0 ? void 0 : _vm$form7$meta.dimension) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "Edition"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$meta = _vm$form8.meta) === null || _vm$form8$meta === void 0 ? void 0 : _vm$form8$meta.edition) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "제작연도(추정)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : (_vm$form9$meta = _vm$form9.meta) === null || _vm$form9$meta === void 0 ? void 0 : _vm$form9$meta.year) || "-") + " ")]), _c('myinfo-row', {
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "label": "작품설명"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : (_vm$form10$meta = _vm$form10.meta) === null || _vm$form10$meta === void 0 ? void 0 : _vm$form10$meta.content) || "-") + " ")]), _c('myinfo-row', {
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "label": "기타"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : (_vm$form11$meta = _vm$form11.meta) === null || _vm$form11$meta === void 0 ? void 0 : _vm$form11$meta.etc) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "작품 이미지"
    }
  }, [_c('v-row', [((_ref = ((_vm$form12 = _vm.form) === null || _vm$form12 === void 0 ? void 0 : _vm$form12.files) || []) === null || _ref === void 0 ? void 0 : _ref.length) < 1 ? _c('v-col', [_vm._v(" 이미지가 없습니다. ")]) : _vm._e(), _vm._l(((_vm$form13 = _vm.form) === null || _vm$form13 === void 0 ? void 0 : _vm$form13.files) || [], function (file) {
    return _c('v-col', {
      key: file._id,
      attrs: {
        "cols": "1"
      }
    }, [_c('image-popup', {
      attrs: {
        "src": file.url,
        "aspect-ratio": 1 / 1,
        "width": "100%"
      }
    })], 1);
  })], 2)], 1), _c('myinfo-row', {
    attrs: {
      "label": "구입가(KRW)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form14 = _vm.form) === null || _vm$form14 === void 0 ? void 0 : (_vm$form14$meta = _vm$form14.meta) === null || _vm$form14$meta === void 0 ? void 0 : _vm$form14$meta.price_bought) || "-") + " ")]), _c('myinfo-row', {
    attrs: {
      "label": "희망가(KRW)"
    }
  }, [_vm._v(" " + _vm._s(((_vm$form15 = _vm.form) === null || _vm$form15 === void 0 ? void 0 : (_vm$form15$meta = _vm$form15.meta) === null || _vm$form15$meta === void 0 ? void 0 : _vm$form15$meta.price_asking) || "-") + " ")])], 1)]), _c('v-divider')], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(2), _c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-16 pa-md-24"
  }, [_c('div', {
    staticClass: "page-text",
    domProps: {
      "innerHTML": _vm._s(((_vm$form16 = _vm.form) === null || _vm$form16 === void 0 ? void 0 : _vm$form16.reply) || '답변 내용이 없습니다.')
    }
  })])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("목록으로")])])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("위탁 신청인 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("위탁 작품 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("답변 내용")])]);

}]

export { render, staticRenderFns }