<template>
    <div class="form-container" v-if="value">
        <!-- 위탁 신청 전에 읽어주세요 -->

        <!-- 위탁 신청인 정보 -->
        <div>
            <div class="mb-10">
                <h3 class="tit tit--xs">위탁 신청인 정보</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="이름"> {{ form?.meta?.name || "-" }} </myinfo-row>
                    <myinfo-row label="연락처"> {{ form?.meta?.phone || "-" }} </myinfo-row>
                    <myinfo-row label="이메일"> {{ form?.meta?.email || "-" }} </myinfo-row>
                    <myinfo-row label="주소">
                        <template v-if="!!form.meta.postcode"> [{{ form.meta.postcode }}] </template>
                        <template v-if="!!form.meta.address1"> {{ form.meta.address1 }} </template>
                        <template v-if="!!form.meta.address2"> {{ form.meta.address2 }} </template>
                    </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>
        <!-- 위탁 신청인 정보 -->

        <!-- 위탁 작품 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">위탁 작품 정보</h3>
            </div>
            <v-divider class="primary border-2"></v-divider>
            <div class="py-20 px-md-10 py-md-36">
                <div class="mt-n8 mt-md-n14">
                    <myinfo-row label="작가명"> {{ form?.meta?.author || "-" }} </myinfo-row>
                    <myinfo-row label="작품명"> {{ form?.meta?.subject || "-" }} </myinfo-row>
                    <myinfo-row label="작품의 재료"> {{ form?.meta?.material || "-" }} </myinfo-row>
                    <myinfo-row label="작품의 크기"> {{ form?.meta?.dimension || "-" }} </myinfo-row>
                    <myinfo-row label="Edition"> {{ form?.meta?.edition || "-" }} </myinfo-row>
                    <myinfo-row label="제작연도(추정)"> {{ form?.meta?.year || "-" }} </myinfo-row>
                    <myinfo-row label="작품설명" style="white-space:pre-line"> {{ form?.meta?.content || "-" }} </myinfo-row>
                    <myinfo-row label="기타" style="white-space:pre-line"> {{ form?.meta?.etc || "-" }} </myinfo-row>
                    <myinfo-row label="작품 이미지">
                        <v-row>
                            <v-col v-if="(form?.files || [])?.length < 1">
                                이미지가 없습니다.
                            </v-col>
                            <v-col cols="1" v-for="file in form?.files || []" :key="file._id">
                                <image-popup :src="file.url" :aspect-ratio="1 / 1" width="100%" />
                            </v-col>
                        </v-row>
                    </myinfo-row>
                    <myinfo-row label="구입가(KRW)"> {{ form?.meta?.price_bought || "-" }} </myinfo-row>
                    <myinfo-row label="희망가(KRW)"> {{ form?.meta?.price_asking || "-" }} </myinfo-row>
                </div>
            </div>
            <v-divider />
        </div>
        <!-- 위탁 작품 정보 -->

        <!-- 답변 내용 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">답변 내용</h3>
            </div>
            <v-card tile elevation="0" class="grey-ef">
                <v-card-text class="pa-16 pa-md-24">
                    <div class="page-text" v-html="form?.reply || '답변 내용이 없습니다.'"></div>
                </v-card-text>
            </v-card>
        </div>
        <!-- 답변 내용 -->

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">목록으로</span></v-btn>
                <!-- <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">위탁신청</v-btn> -->
            </div>
        </div>
    </div>
</template>

<script>
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

export default {
    components: {
        MyinfoRow,
        ImagePopup,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: this.$props.value,
        };
    },
    watch: {
        value() {
            this.form = this.value;
        },
    },
    methods: {
        input() {
            this.$emit("input", this.form);
        },
    },
};
</script>
